<template>
	<div style="width:100%;height:845px;">
		<div style="width:62%;height:150px;margin:0 auto;background-color:#fff">
			<h3 style="font-size:30px;color:#454545;margin-bottom: 10px">联系我们</h3>
			<p style="font-size:15px;color:#454545;">Contact us</p>
		</div>
		<div class="lxwmw">
			<img style="width:29%" src="../../assets/lxwmbj.png" alt="">
			<div>
				<div class="lyti">
					<h3 style="color:#060a3a:">写下您的需求</h3>
					<h3 style="color:#0f91ee">我们马上给您回复</h3>
					<p style="color:#9f9f9f">请填写你的需求我们会第一时间与您联系</p>
					<div class="rx">电话热线：18695152532</div>

				</div>
				<div class="frombd">
					<el-form ref="ruleForm" :rules="rules"  :model="info" style="margin-left:-6%;margin-top: 0%;" label-width="100px" class="demo-ruleForm">
						<el-form-item label="姓名" style="margin-bottom:3%;" prop="name" ref="name">
							<el-input style="width:50%" v-model="info.name" type="text"></el-input>
						</el-form-item>
						<el-form-item label="电话" style="margin-bottom:3%"  prop="phone" ref="phone">
							<el-input style="width:50%" v-model="info.phone" type="text"></el-input>
						</el-form-item>
						<el-form-item label="邮箱" style="margin-bottom:3%" prop="email" ref="email">
							<el-input style="width:50%" v-model="info.email" type="text"></el-input>
						</el-form-item>
						<el-form-item label="公司" style="margin-bottom:3%" prop="company" ref="company">
							<el-input style="width:50%" v-model="info.company" type="text"></el-input>
						</el-form-item>
						<el-form-item label="备注" class="lyb" prop="message" ref="message">
							<!-- min-height:90px !important; -->
							<el-input  style="width:50%;" :rows="10" type="textarea" v-model="info.message"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('info')">提交
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="contact_us">
				<div class="contact_ustext">在线联系我们</div>
				<div class="icon_text">
					<div class="specific">
						<img src="../../assets/dz3.png" alt="">
						<p>宁夏银川市ibi育成中心2期7号楼506</p>
					</div>
					<div class="specific1">
						<img src="../../assets/email3.png" alt="">
						<p> cyx@nxcyx.com</p>
					</div>
					<div class="specific2">
						<img src="../../assets/dh3.png" alt="">
						<p> 0951-7862828</p>
					</div>
					<div class="specific3">
						<img src="../../assets/sj3.png" alt="">
<!--						<p>18695152532</p>-->
<!--						<p>13895114707</p>-->
						<p>18709507817</p>
						<p>18695156699</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ContactUs",
	data(){
		return {
			//  表单信息
			info: {
				name:'',
				eamil:'',
				phone:'',
				company:'',
				message:''
			} ,
			//  表单验证
			rules: {
				name: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ min: 2, max: 5, message: '长度在 2到 6个字符', trigger: 'blur' }
				],
				phone:[
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' }
				],
				email:[
					{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
				],
				company:[
					{ required: true, message: '请输入公司名称', trigger: 'blur' },
					{ min: 2, max: 20, message: '请输入正确的公司名称', trigger: 'blur' }
				],
				message:[
					{ required: true, message: '请输入留言', trigger: 'blur' },
					{ min: 2, max: 60, message: '请正确输入留言', trigger: 'blur' }
				]
			} ,
		}
	},
	methods:{
		// 提交表单
		async submitForm(info) {
			await this.$refs["ruleForm"].validate(async (valid) => {
				if (valid) {
					const {data: res} = await this.$http.get('/gw/news/saveContactUs?name=' + this.info.name + '&phone=' + this.info.phone + '&email=' + this.info.email + '&company=' + this.info.company + '&msg=' + this.info.message + '')
					if (res.code == 0) {
						alert('感谢你的留言');
						this.$refs['name'].resetField();
						this.$refs['email'].resetField()
						this.$refs['phone'].resetField()
						this.$refs['company'].resetField()
						this.$refs['message'].resetField()
					} else {
						alert(res.msg)
					}
				}
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.introduction1>div {
	display: inline-block;
	width: 300px;
	/* margin-top: 50PX;
	margin-left: 150px; */
}
.introduction1>div>img{
	padding-top: 50px;
	padding-left: 95px;
}
.introduction1>div>h3{
	color: #717171;
	text-align: center;
	margin-top: 8px;

}
.introduction1>div>p{
	color: #717171;
	text-align: center;
	width: 250px;
	margin-left: 20px;
}
.xm>h2{
	margin-left: 19%;
}
.el-dropdown + .el-dropdown {
	margin-left: 15px;
	width: 500px !important;
}
.cooperation>div {
	display: inline-block;
	margin-right: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 15%;
	height: 50px;
	border: 1px solid #717171;
	text-align: center;
	overflow: hidden;
	font-size: 13px;
}
.cooperation>div>span {
	width: 188px;
	height: 50px;
	text-align: center;
	margin-top: 50px;
	line-height: 44px;
}
.footerkuai>div {
	display: inline-block;
	/* border: 1px solid #fff; */
	color: #4b4f54;
}
.sj1>p {
	display: inline-block;
}
.dhbj>p {
	position: absolute;
	top: 5px;
	color: #494d52;
}
.lxwmw {
	position: relative;
	width:62%;
	height: 680px;
	/* background-color: aqua; */
	margin: 0 auto;
}
.lyti {
	width: 39%;
	/* background-color: red; */
	margin-left: 34%;
	margin-top: -56%;
}
.lyti>h3{
	display: inline-block;
	font-size: 18px;
	margin-bottom: 0;
}
.rx {
	width: 50%;
	height: 23px;
	/* background-color: #ee880f; */
	border-radius: 50px;
	text-align: center;
	padding-top: 2%;
	color: azure;
}
.frombd {
	width: 485;
	height: 650px;
	margin-left:34% ;
}
.contact_us {
	position: absolute;
	height: 580px;
	width: 23%;
	top: 0%;
	right: 0%;
	/* background-color: red; */

}
.contact_ustext {
	/* border: solid 1px #fff; */
	background-color: #ee880f;
	width: 38%;
	height: 8%;
	font-size: 14px;
	text-align: center;
	line-height: 42px;
	margin-left: 3%;
	color: #fff;
}
.icon_text {
	width: 100%;
	height: 50%;
	margin-top: 20%;
	font-size: 13px;
	/* background-color: aqua; */
}
.specific{
	position: relative;
	width: 100%;
	height: 12%;
	/* background-color: blue; */
}
.specific>img {
	position: absolute;
	top: 15%;
	display: inline-block;
}
.specific>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
}
.specific1{
	position: relative;
	width: 100%;
	height: 12%;
	/* background-color: blue; */
}
.specific1>img {
	position: absolute;
	top: 34%;
	display: inline-block;
}
.specific1>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
}
.specific2{
	position: relative;
	width: 100%;
	height: 12%;
	/* background-color: blue; */
	margin-bottom: 5%;
}
.specific2>img {
	position: absolute;
	top: 30%;
	display: inline-block;
}
.specific2>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
}
.specific3{
	position: relative;
	width: 100%;
	height: 12%;
	/* background-color: blue; */
}
.specific3>img {
	position: absolute;
	top: 30%;
	display: inline-block;
}
.specific3>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
	margin-top: 0;
	width: 80%;
}

</style>
